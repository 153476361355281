import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { lifecycle, compose } from 'recompose';
import queryString from 'query-string';
import { withTracker, withTrackProps } from '@dpdgroupuk/react-event-tracker';
import { withPageControl } from '@dpdgroupuk/fmx-ui/components/Page';
import FluidContainer from '@dpdgroupuk/fmx-ui/components/FluidContainer';
import { useEnhancedReCaptchaBadge as useGoogleReCaptchaVisibleToggle } from '@dpdgroupuk/fmx-ui/components/GoogleRecaptcha';
import { withModal } from '@dpdgroupuk/fmx-ui/components/Dialog';
import { withOverlay } from '@dpdgroupuk/fmx-ui/components/Overlay';

import { withGoogleReCaptcha } from '@dpdgroupuk/react-google-recaptcha-v3';
import { IMAGES } from '@dpdgroupuk/fmx-ui/themes';
import { MetaRobotsIndex } from '../../../utils/metaRobots';

import ReferenceEntryForm from './ReferenceEntryForm';
import { REFERENCE_ENTRY, trackable } from '../../../constants/analytics';
import { withSearchSubmitHandler } from '../hocs';
import {
  backToDpdSiteHeader,
  backToDpdSiteSpeedDeal,
  backToGreenSiteHeader,
  backToGreenSiteSpeedDeal,
  getDpdAppHeader,
  getDpdAppSpeedDeal,
  trackAnotherParcelHeader,
} from '../../controlActions';
import { MORE_COOL_STUFF } from '../../../constants/message';

type Props = {
  location: Object,
  onSearchSubmit: Function,
};

const ReferenceEntryComponent = ({ location, onSearchSubmit }: Props) => {
  useGoogleReCaptchaVisibleToggle();

  const initialValues = React.useMemo(() => {
    const query = queryString.parse(location.search);

    return {
      referenceNumber: query.reference,
      postcode: query.postcode,
    };
  }, [location]);

  return (
    <>
      <MetaRobotsIndex />
      <FluidContainer>
        <ReferenceEntryForm
          initialValues={initialValues}
          onReferenceSubmit={onSearchSubmit}
        />
      </FluidContainer>
    </>
  );
};

const createHeaderControlActions = props => [
  trackAnotherParcelHeader(props),
  backToDpdSiteHeader(props),
  backToGreenSiteHeader(props),
  getDpdAppHeader(props),
];

const createSpeedDealControlActions = props => [
  backToDpdSiteSpeedDeal(props),
  getDpdAppSpeedDeal(props),
  backToGreenSiteSpeedDeal(props),
];

export default compose(
  withRouter,
  withModal,
  withOverlay,
  withTracker,
  withGoogleReCaptcha,
  withSearchSubmitHandler,
  withTrackProps({
    onSearchSubmit: REFERENCE_ENTRY.SEARCH,
  }),
  withPageControl({
    title: MORE_COOL_STUFF,
    backgroundImage: IMAGES.TRACKING_BG,
    trackable: trackable(REFERENCE_ENTRY),
    headerMenuActions: createHeaderControlActions,
    speedDealActions: createSpeedDealControlActions,
  }),
  lifecycle({
    async componentDidMount() {
      const { location, onSearchSubmit } = this.props;
      const query = queryString.parse(location.search);

      if (query.reference) {
        onSearchSubmit({
          postcode: query.postcode,
          referenceNumber: query.reference,
        });
      }
    },
  })
)(ReferenceEntryComponent);
