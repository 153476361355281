import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import QRCode from 'react-qr-code';
import clsx from 'clsx';
import FallBackImage from '@dpdgroupuk/fmx-ui/components/FallBackImage';
import { useModalState } from '@dpdgroupuk/fmx-ui/hooks';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import arrowFull from '../../../../../assets/images/arrowFull.png';
import PickupPassModal from './PickupPassModal';
import { COLLECT_FROM_PICKUP } from '../../../../../constants/analytics';
import AddToAppleWallet from '../../../../../components/Icons/AddToAppleWallet';
import * as MESSAGE from '../../../../../constants/message';

const PickupPassQRCode = (props: {
  styles: ClassNameMap<
    ClassKeyOfStyles<
      | StyleRules<
          {},
          | 'container'
          | 'mainContainer'
          | 'imgContainer'
          | 'img'
          | 'dialogContainer'
          | 'closeButton'
          | 'textContainer'
          | 'closeIcon'
          | 'arrowFull'
          | 'text'
          | 'fontWeight400'
          | 'arrowButton'
        >
      | (theme => StyleRules<
          {},
          | 'container'
          | 'mainContainer'
          | 'imgContainer'
          | 'img'
          | 'dialogContainer'
          | 'closeButton'
          | 'textContainer'
          | 'closeIcon'
          | 'arrowFull'
          | 'text'
          | 'fontWeight400'
          | 'arrowButton'
        >)
    >
  >,
  src: string,
  parcelPickupCode: any,
  isShop: boolean,
  appleWalletPassUrl: string,
}) => {
  const [open, show, hide] = useModalState(false);
  const tracker = useTracker();

  const onCloseModal = React.useCallback(() => {
    hide();
    tracker.logEvent(COLLECT_FROM_PICKUP.CLOSE_PICKUP_PASS);
  }, [tracker, hide]);

  const onShowModal = React.useCallback(() => {
    show();
    tracker.logEvent(COLLECT_FROM_PICKUP.SHOW_SHOP_MAP);
  }, [tracker, show]);

  const QrCode = props.isShop ? (
    <FallBackImage src={props.src} alt="" className={props.styles.img} />
  ) : (
    <div className={props.styles.img}>
      <QRCode value={props.parcelPickupCode || '00000000'} size={164} />
    </div>
  );

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={props.styles.imgContainer}
        onClick={onShowModal}
      >
        <IconButton
          aria-label="close"
          size="small"
          className={props.styles.arrowButton}
        >
          <img src={arrowFull} alt="" className={props.styles.arrowFull} />
        </IconButton>
        {QrCode}
      </Grid>
      <Grid className={props.styles.pinContainer}>
        <Grid container direction="row" className={props.styles.textContainer}>
          {props.isShop ? (
            <></>
          ) : (
            <Typography
              className={clsx(
                props.styles.text,
                props.appleWalletPassUrl && props.styles.pinContainer
              )}
            >
              Scan barcode or enter pin {props.parcelPickupCode}
            </Typography>
          )}
        </Grid>
        {props.appleWalletPassUrl && (
          <>
            <Typography className={props.styles.yourDpdPickup}>
              {MESSAGE.ADD_PASS_TO_YOUR_WALLET}
            </Typography>
            <a
              href={props.appleWalletPassUrl}
              onClick={() => {
                tracker.logEvent(COLLECT_FROM_PICKUP.ON_ADD_TO_APPLE_WALLET);
              }}
              className={props.styles.addToAppleWalletButton}
            >
              <AddToAppleWallet width="162px" height="50px" />
            </a>
          </>
        )}
      </Grid>
      <PickupPassModal open={open} QrCode={QrCode} onClose={onCloseModal} />
    </Grid>
  );
};

export default PickupPassQRCode;
